<template>
  <div class="v-application__wrap">
    <div class="pa-3">
      <div class="v-row h-100vh mh-100 auth">
        <div
          class="v-col-lg-7 v-col-xl-8 v-col-12 d-lg-flex align-center justify-center authentication position-relative"
        >
          <div class="auth-header pt-lg-6 pt-2 px-sm-6 px-3 pb-lg-6 pb-0">
            <div class="position-relative">
              <div class="logo">
                <a href="/">
                  <img src="@/assets/img/logo-dark.svg" alt="Logo" />
                </a>
              </div>
            </div>
          </div>
          <div>
            <img
              src="@/assets/img/conveniq-logo.png"
              height="450"
              class="position-relative d-none d-lg-flex w-100"
              alt="login-background"
            />
          </div>
        </div>
        <div
          class="v-col-lg-5 v-col-xl-4 v-col-12 d-flex align-center justify-center bg-surface"
        >
          <div class="mt-xl-0 mt-5 mw-100">
            <h2 class="text-h3 font-weight-semibold mb-2 text-center">
              Welcome to Conveniq
            </h2>
            <!-- <div class="text-subtitle-1 mb-6 text-center">Admin Dashboard</div> -->
            <div class="v-row d-flex mb-3">
              <!-- <div class="v-col-sm-6 v-col-6 pr-2">
                <base-button
                  :variant="'pure'"
                  className="v-btn v-btn--block v-btn--density-default v-btn--size-large v-btn--variant-outlined border text-subtitle-1"
                >
                  <img
                    src="@/assets/icons/google-icon.svg"
                    height="16"
                    class="mr-2"
                    alt="google"
                  />
                  <span class="d-sm-flex d-none mr-1">Sign in with</span> Google
                </base-button>
              </div>
              <div class="v-col-sm-6 v-col-6 pr-2">
                <base-button
                  :variant="'pure'"
                  className="v-btn v-btn--block v-btn--density-default v-btn--size-large v-btn--variant-outlined border text-subtitle-1"
                >
                <img
                      src="@/assets/icons/facebook-icon.svg"
                      height="25"
                      width="25"
                      class="mr-2"
                      alt="google"
                    />
                  <span class="d-sm-flex d-none mr-1">Sign in with</span> FB
                </base-button>
              </div> -->
            </div>
            <!-- <div class="d-flex align-center text-center mb-6">
              <div
                class="text-h6 w-100 px-5 font-weight-regular auth-divider position-relative"
              >
                <span class="bg-surface px-5 py-3 position-relative"
                  >or sign in with</span
                >
              </div>
            </div> -->
            <div class="">

                <base-input
                  labelName="Email*"
                  v-model:value="email"
                  placeholder="Email"
                  ref="userNameInput"
                  class="mb-2"
                  type="email"
                  @keypress.enter="validateAndLogin"
                ></base-input>
                <base-input
                  labelName="Password*"
                  v-model:value="password"
                  placeholder="Password"
                  type="password"
                  ref="passwordInput"
                  @keypress.enter="validateAndLogin"
                ></base-input>
                <div class="d-flex flex-wrap align-center my-3 ml-n2">
                  <div
                    class="v-input v-input--horizontal v-input--density-default v-input--dirty v-checkbox"
                  >
                    <!-- <div class="v-input__control">
                    <div
                      class="v-selection-control v-selection-control--inline v-selection-control--density-default v-checkbox-btn"
                    >
                      <div class="v-selection-control__wrapper">               
                        <div class="v-selection-control__input">
                          <i
                            class="mdi-checkbox-blank-outline mdi v-icon notranslate v-theme--BLUE_THEME v-icon--size-default"
                            aria-hidden="true"
                          ></i
                          ><input
                            id="checkbox-119"
                            aria-readonly="false"
                            type="checkbox"
                            indeterminateicon="$checkboxIndeterminate"
                            value="true"
                          />
                        </div>
                      </div>
                      <label
                        class="v-label v-label--clickable"
                        for="checkbox-119"
                        >Remeber this Device</label
                      >
                    </div>
                  </div> -->
                  </div>
                  <!-- <div class="ml-sm-auto">
                    <a
                      aria-current="page"
                      href="/user/login"
                      class="router-link-active router-link-exact-active text-primary text-decoration-none text-body-1 opacity-1 font-weight-medium"
                      >Forgot Password ?</a
                    >
                  </div> -->
                </div>
                <base-button
                  :variant="'pure'"
                  @click="validateAndLogin"
                  className="v-btn v-btn--block v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-large v-btn--variant-elevated"
                  >Sign In
                </base-button>               

            </div>

            <!-- <h6
              class="text-h6 text-medium-emphasis d-flex align-center mt-6 font-weight-medium"
            >
              Not registred?
              <a
                class="v-btn v-theme--BLUE_THEME v-btn--density-default v-btn--size-default v-btn--variant-plain pl-0 text-primary text-body-1 opacity-1 pl-2 font-weight-medium"
                href="/auth/register"
                style="height: auto"
                ><span class="v-btn__overlay"></span
                ><span class="v-btn__underlay"></span
                ><span class="v-btn__content" data-no-activator=""
                  >Create an account</span
                ></a
              >
            </h6> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/userStore";
import UserApi from "@/api/userApi";
const baseURL = process.env.VUE_APP_API_URL;

export default {
  name: "Login Page",
  props: {},
  components: { BaseInput, BaseButton },
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(useUserStore, ["setToken", "setUser", "goToPrevUrl", "logout"]),
    showNotis() {
      this.$notify(
        {
          group: "alert",
          type:"error",
          title: "Error",
          text: "Your account was not registered!",
        },
        2000
      );
    },
    validateAndLogin() {
      if (this.email?.length > 5 && this.password?.length > 6) {
        this.loginByPasw(this.email, this.password);
      }
      else {
        this.$notify(
              {
                group: "alert",
                type:"error",
                title: "Error",
                text: "Fill in the required fields",
              },
              2000
            );
      }
    },
    async loginByPasw(email, password) {
      let dataRequest = {
        email: email,
        password: password,
      }; 
        this.axios
          .post(`${baseURL}/api/auth/login`, dataRequest, {
            skipAuthRefresh: true,
            withCredentials: true,
            interceptNetworkError: false
          }).catch(
           ( error) => {
              console.log(error?.code);
            }
          )          
         .then(async (response) => {
            if (response?.data?.status === "success") {
              this.setToken(response.data.access_token);
              let user = await UserApi.getUserInfo();
              if (user) {
                this.setUser(user);
                this.goToPrevUrl();
              }
            }
            else {             
              this.$notify(
              {
                group: "alert",
                type:"error",
                title: "Error",
                text: "Authorization error!",
              },
              2000
            );
            }
          })     
    },
    async loginByToken(token){
        let dataRequest = {};      
        this.axios
          .post(`${baseURL}/api/auth/login?temporary_token=${token}`, dataRequest, {
            skipAuthRefresh: true,
            withCredentials: true,
            interceptNetworkError: false
          }).catch(
           ( error) => {
              console.log(error?.code);
            }
          )          
         .then(async (response) => {
            if (response?.data?.status === "success") {
              this.setToken(response.data.access_token);
              let user = await UserApi.getUserInfo();
              if (user) {
                this.setUser(user);
                this.goToPrevUrl();
              }
            }
            else {             
              this.$notify(
              {
                group: "alert",
                type:"error",
                title: "Error",
                text: "Authorization error!",
              },
              2000
            );
            }
          })
    }
  },
  async mounted() { 
    if(this.$route.query?.temporary_token?.length > 10){ 
      this.loginByToken(this.$route.query?.temporary_token)     
    }
    else if(this.$route.query?.email?.length > 10 && this.$route.query?.password?.length > 6){
       this.loginByPasw(this.$route.query.email, this.$route.query.password);
    }
  },
};
</script>
<style lang="scss">
.mw-100 {
  min-width: 100%;
}
</style>
